import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            title: {
                paddingBottom: theme.spacing(1),
            },
            description: {
                paddingBottom: theme.spacing(1),
            },
            input: {
                height: '9em',
                margin: '6px 0 6px',
                width: '24em',
                whiteSpace: 'nowrap',
                [theme.breakpoints.only('xs')]: {
                    width: '80%',
                    whiteSpace: 'wrap',
                },
            },
        }),
    {
        name: 'InstitutionalEmail-',
    },
);
