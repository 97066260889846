import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            title: {
                paddingBottom: theme.spacing(1),
            },
            input: {
                margin: '12px 0 20px',
                width: '6em',
                whiteSpace: 'nowrap',
                [theme.breakpoints.only('xs')]: {
                    width: '80%',
                    whiteSpace: 'wrap',
                },
            },
            timeoutContainer: {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
            },
        }),
    {
        name: 'SessionTimeoutDuration-',
    },
);
