import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            heading: {
                paddingBottom: theme.spacing(1),
            },
            description: {
                paddingBottom: theme.spacing(3),
            },
            divider: {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
            },
            buttonContainer: {
                alignItems: 'center',
                marginLeft: theme.spacing(-4),
                marginRight: theme.spacing(-4),
                marginBottom: theme.spacing(-4),
                padding: theme.spacing(2),
                columnGap: theme.spacing(2),
                justifyContent: 'end',
                borderTop: 'solid 0.0625rem #cdcdcd',
                backgroundColor: '#f8f8f8',
            },
            cancelButton: {
                width: '13rem',
                height: '2.5rem',
            },
            saveButton: {
                width: '13rem',
                height: '2.5rem',
            },
            snackbarButton: {
                color: theme.palette.common.white,
                direction: 'rtl',
            },
        }),
    {
        name: 'GlobalSettings-',
    },
);
