import React from 'react';
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from 'react-i18next';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useStyles } from './InstitutionalEmail.styles';

const EMAIL_PLACEHOLDER = 'user.email@institution.edu.com';

interface InstitutionalEmailProps {
    email?: string;
    disabled?: boolean;
    onChange: (email: string, isValid: boolean) => void;
    cancelChanges?: boolean;
}

export const InstitutionalEmail: React.FC<InstitutionalEmailProps> = ({
    email,
    disabled,
    onChange,
    cancelChanges,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [displayError, setDisplayError] = React.useState(false);

    React.useEffect(() => {
        setDisplayError(false);
    }, [cancelChanges]);

    return (
        <>
            <Typography
                variant="h3"
                className={classes.title}
                data-testid="institutional-email-title"
            >
                {t('settings.globalSettings.email.title')}
            </Typography>
            <Typography
                component="p"
                className={classes.description}
                data-testid="institutional-email-description"
            >
                {t('settings.globalSettings.email.description')}
            </Typography>

            <TextField
                id="institutional-email-input"
                data-testid="institutional-email-input"
                type="email"
                inputMode="email"
                className={classes.input}
                label={t('settings.globalSettings.email.input')}
                disabled={disabled}
                placeholder={EMAIL_PLACEHOLDER}
                value={email}
                onChange={event => {
                    const enteredEmail = event.target.value.trim();
                    setDisplayError(false);
                    // Empty email is considered a valid value
                    const isValid = enteredEmail ? isEmail(enteredEmail) : true;
                    onChange(enteredEmail, isValid);
                }}
                onBlur={event => {
                    const enteredEmail = event.target.value.trim();
                    if (enteredEmail) {
                        setDisplayError(!isEmail(enteredEmail));
                    }
                }}
                error={displayError}
                helperText={
                    displayError && t('settings.globalSettings.email.invalid')
                }
            />
        </>
    );
};
