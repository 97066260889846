import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import axios from 'axios';
import classnames from 'classnames';
import React, {
    FunctionComponent,
    Suspense,
    lazy,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useLocalStorage, usePrevious, useSessionStorage } from 'react-use';
import { Key } from 'ts-key-enum';

import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { AuthOnlyContent } from '@bb-ui/auth/dist/auth0/AuthOnlyContent';
import { ThreeBarMenu } from '@bb-ui/icons/dist/medium/ThreeBarMenu';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Drawer } from '@bb-ui/react-library/dist/components/Drawer';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';

import { DoubleChevronLeft } from '@bb-ui/icons/dist/small/DoubleChevronLeft';
import { Outline } from '@bb-ui/icons/dist/small/Outline';

import { Close } from '@bb-ui/icons/dist/medium';
import { Link, useSnackbar } from '@bb-ui/react-library';
import { useIdleTimer } from 'react-idle-timer';
import { PageHeader } from '../PageHeader/PageHeader';
import { PrivacyAndSecurity } from '../PrivacyAndSecurity/PrivacyAndSecurity';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { useStyles } from './MainPage.styles';

import { useAppLoadingContext } from '../../contexts/AppLoadingContext';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { Footer } from '../Footer/Footer';
import { ListNav } from '../ListNav/ListNav';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { SideBar } from '../SideBar/SideBar';
import { SignInModal } from '../SignInModal/SignInModal';
import { SkipLink } from '../SkipLink/SkipLink';

import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { useFeatureContext } from '../../contexts/FeatureContext';
import { PageExpandProvider } from '../../contexts/PageExpandContext';
import { FeatureName } from '../../featureFlags/Feature.types';
import { SilentFeatureFlagIndicator } from '../../featureFlags/SilentFeatureFlagIndicator';
import { useUser } from '../../hooks/useUser';
import { localStorageUASTenantKey } from '../../utilities/constants';
import {
    getReportApiLanguage,
    getWordBreakStyle,
} from '../../utilities/localization';
import { CustomReports } from '../CustomReports/CustomReports';
import { CustomReportsTeaser } from '../CustomReports/CustomReportsTeaser';
import { DataQAndA } from '../DataQAndA/DataQAndA';
import { EditUserPage } from '../Settings/EditUserPage/EditUserPage';
import { DeveloperPage } from '../DeveloperPage/DeveloperPage';
import { HomePage } from '../HomePage/HomePage';
import { useI18nContext } from '../I18nProvider/I18nProvider';
import { BadgeType } from '../ListNav/ListNav.types';
import { Settings } from '../Settings/Settings';
import {
    IQuickSightDashboard,
    IQuickSightReport,
    ReportingArea,
    TenantStatus,
    TenantStatusApiValue,
} from './MainPage.types';
import { useTenantSettingsContext } from '../../contexts/TenantSettingsContext';

const Report = lazy(
    () => import(/* webpackChunkName: 'report' */ '../Report/Report'),
);

const ReportingPage = lazy(
    () =>
        import(
            /* webpackChunkName: 'reportingpage' */ '../ReportingPage/ReportingPage'
        ),
);

const ReportAreaPage = lazy(
    () =>
        import(
            /* webpackChunkName: 'reportareapage' */ '../ReportingAreaPage/ReportingAreaPage'
        ),
);

const DataDictionary = lazy(
    () =>
        import(
            /* webpackChunkName: 'datadictionary' */ '../DataDictionary/DataDictionary'
        ),
);

const QuickSightReport = lazy(
    () =>
        import(
            /* webpackChunkName: 'qsreport' */ '../../quicksight/QuickSightReport'
        ),
);

export const MainPage: FunctionComponent = () => {
    const user = useUser();
    const { t } = useTranslation();
    const {
        uas,
        api,
        featureFlags: configFeatureFlags,
    } = useAppConfigContext();
    const { userSelectedLang } = useI18nContext();
    const { isAuthenticated, logout, accessToken } = useAuth0Context();
    const featureFlags = useFeatureContext() ?? [];

    const classes = useStyles();

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const { setLoading } = useAppLoadingContext();
    const {
        openNavDrawer,
        openGlobalNavDrawer,
        openSecondaryNavDrawer,
        closeSecondaryNavDrawer,
        openSignInModal,
    } = useAppNavigationContext();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const mainContentRef = useRef<HTMLElement>(null);
    const closeGlobalNavDrawerButtonRef = useRef<HTMLButtonElement>(null);
    const openGlobalNavDrawerButtonRef = useRef<HTMLButtonElement>(null);
    const closeSecondaryNavDrawerButtonRef = useRef<HTMLButtonElement>(null);
    const openSecondaryNavDrawerButtonRef = useRef<HTMLButtonElement>(null);

    const [tenantStatus, setTenantStatus] = useState<TenantStatus>();

    const path = useLocation().pathname;

    const pathsWithSecondaryNav = [
        { regEx: '^/dictionary', path: '/dictionary' },
        { regEx: '^/reporting/learning/.', path: '/reporting/learning' },
        { regEx: '^/reporting/teaching/.', path: '/reporting/teaching' },
        { regEx: '^/reporting/leading/.', path: '/reporting/leading' },
    ];

    const matchIndex = pathsWithSecondaryNav.findIndex(obj =>
        path.match(obj.regEx),
    );

    // If we match one of the regEx patterns, then we should show a secondary nav
    const pageHasSecondaryNav = matchIndex !== -1;
    // Page match will include leading slash
    const currentPageMatch =
        matchIndex >= 0
            ? pathsWithSecondaryNav[matchIndex].path
            : `/${path.split('/')[1]}`;

    const previousPageMatch = usePrevious(currentPageMatch);

    const globalNavOpen = openNavDrawer === 'global';
    const secondaryNavOpen = openNavDrawer === 'secondary';

    const [uasTenant, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );

    const { data: { timeout, aiOptIn = false } = {} } =
        useTenantSettingsContext();
    const [sessionEnded, setSessionEnded] = useSessionStorage(
        'sessionEnded',
        false,
    );

    const logoutAndNavigateToHome = () => {
        setUasTenant('');
        const loggedOutPath = `${window.location.origin}/`;
        if (logout && isAuthenticated) {
            logout({
                returnTo: loggedOutPath,
            });
        } else {
            console.error(
                'Failed to log out: `logout` function does not exist',
            );
        }
    };

    // This is logic for IDLE session timeout
    const onIdle = () => {
        // Log out user
        if (logout && isAuthenticated) {
            setSessionEnded(true);
            logoutAndNavigateToHome();
        }
    };

    const onActive = () => {};

    const { reset } = useIdleTimer({
        onIdle,
        onActive,
        timeout: timeout ? timeout * 1000 : 900000,
        crossTab: true,
        eventsThrottle: 200,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus',
            'change',
            'select',
        ],
    });

    useEffect(() => {
        console.warn('Reset timer with timeout (in seconds):', timeout);
        reset();
    }, [timeout, reset]);

    // If session ended due to IDLE session timeout, snackbar has to appear
    useEffect(() => {
        if (currentPageMatch !== previousPageMatch && sessionEnded === true) {
            // Inform user about logout via snackbar
            enqueueSnackbar(t('landingPage.idleLogout'), {
                action: (
                    <>
                        <Link
                            component="button"
                            onClick={() => openSignInModal()}
                            aria-label={t('auth.login')}
                            rel="noopener noreferrer"
                            style={{
                                // Force font size to stay at default and weight to stay at semibold since we are using a custom style
                                fontSize: theme.typography.fontSizeDefault,
                                fontWeight: theme.typography.fontWeightSemiBold,
                            }}
                        >
                            {t('auth.login')}
                        </Link>
                        <IconButton
                            className={classes.IdleSnackbarButton}
                            onClick={() => closeSnackbar()}
                            aria-label={t('landingPage.closeSnackbar')}
                        >
                            <Close />
                        </IconButton>
                    </>
                ),
                autoHideDuration: null,
            });
            setSessionEnded(false);
        }
    }, [
        enqueueSnackbar,
        t,
        theme.direction,
        openSignInModal,
        closeSnackbar,
        classes.IdleSnackbarButton,
        theme.typography.fontSizeDefault,
        theme.typography.fontWeightSemiBold,
        sessionEnded,
        setSessionEnded,
        currentPageMatch,
        previousPageMatch,
    ]);

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    // When transitioning between the main pages (and on initial page load), we need to
    // start with the secondary nav drawer open, if there is one needed for the current page
    useEffect(() => {
        if (currentPageMatch !== previousPageMatch && pageHasSecondaryNav) {
            openSecondaryNavDrawer();
        }
    }, [
        pageHasSecondaryNav,
        currentPageMatch,
        previousPageMatch,
        openSecondaryNavDrawer,
        closeSecondaryNavDrawer,
    ]);

    // Make sure that the secondary nav is set to closed when on a page that doesn't have a secondary nav
    useEffect(() => {
        if (secondaryNavOpen && !pageHasSecondaryNav) {
            closeSecondaryNavDrawer();
        }
    }, [pageHasSecondaryNav, secondaryNavOpen, closeSecondaryNavDrawer]);

    // QuickSight Dashboards loading state
    const [quickSightDashboardsLoading, setQuickSightDashboardsLoading] =
        useState<boolean>(true);

    const [qsReports, setQsReports] = useState<IQuickSightReport[]>();

    const getReportingArea = (name: string): ReportingArea | undefined => {
        // Grab the report name without the tenant id prefixed to it
        const reportName = name.split('-')[1];

        if (reportName.startsWith('LEARNING')) {
            return ReportingArea.Learning;
        }
        if (reportName.startsWith('TEACHING')) {
            return ReportingArea.Teaching;
        }
        if (reportName.startsWith('LEADING')) {
            return ReportingArea.Leading;
        }
    };

    const filteredByFeatureFlagReports = qsReports?.filter(
        report =>
            !report.featureToggleName || featureFlags[report.featureToggleName],
    );
    const filterByReportingArea = (
        area: ReportingArea,
        reports?: IQuickSightReport[],
    ) => reports?.filter(report => report.reportingArea === area) ?? [];

    const learningReports = filterByReportingArea(
        ReportingArea.Learning,
        filteredByFeatureFlagReports,
    );
    const teachingReports = filterByReportingArea(
        ReportingArea.Teaching,
        filteredByFeatureFlagReports,
    );
    const leadingReports = filterByReportingArea(
        ReportingArea.Leading,
        filteredByFeatureFlagReports,
    );

    const userIsSignedInWithIncAuth = user?.isIncrementalUser();

    const [userHasQSSeat, setUserHasQSSeat] = useState<boolean>(true);

    // user has access to settings page
    const userHasSettingsAccess =
        configFeatureFlags?.settingsEnabled &&
        (user?.isInstitutionAdmin() || user?.isSupport());

    // user has access to reporting capabilities in reporting areas
    const userHasReportingAccess =
        configFeatureFlags?.reportsEnabled && user?.hasReportAccess();

    const userHasDataQAndA = user?.hasDataQAndA() && aiOptIn;

    const getReportLinks = useCallback(
        (reports: IQuickSightReport[]) =>
            reports.map(report => ({
                path: `/reporting/${report.reportingArea.toLowerCase()}/${
                    report.urlSlug
                }`,
                label: report.locale.reportTitle,
                testId: `secondary-nav-${report.urlSlug}-link`,
            })),
        [],
    );

    const mapTenantStatus = (apiResponse: string) => {
        if (
            apiResponse === TenantStatusApiValue.Asleep ||
            apiResponse === TenantStatusApiValue.DeprovisioningRequested ||
            apiResponse === TenantStatusApiValue.InDeprovisioning
        ) {
            return TenantStatus.Asleep;
        }
        if (
            apiResponse === TenantStatusApiValue.ProvisioningRequested ||
            apiResponse === TenantStatusApiValue.InProvisioning
        ) {
            return TenantStatus.Provisioning;
        }
        return TenantStatus.Awake;
    };

    const wakeUpReports = () => {
        // The tenant status API is configured to only process Wake Up requests when the tenant is asleep and
        // sleep-status parameter in the API call is set to "awake"
        if (configFeatureFlags?.useTenantStatus) {
            axios
                .put(
                    encodeURI(
                        `${
                            api!.qsHostname
                        }/v1/data/tenants/${user?.tenantIdentifier}/quicksight/sleep-status/${
                            TenantStatusApiValue.Awake
                        }`,
                    ),
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                )
                .then(() => {
                    setTenantStatus(TenantStatus.Provisioning);
                })
                .catch(() => {
                    console.error('Failed to update tenant status');
                });
        }
    };

    useEffect(() => {
        if (
            user?.tenantIdentifier &&
            user?.userId &&
            configFeatureFlags?.reportsEnabled
        ) {
            // Authorization checks:
            // For new reporting areas feature, must have reporting access to proceed.
            if (!userHasReportingAccess) {
                setQuickSightDashboardsLoading(false);
                setQsReports([]);
                return;
            }
            setQuickSightDashboardsLoading(true);

            // Get the tenant status to set the TenantStatus state to the appropriate value
            if (configFeatureFlags?.useTenantStatus) {
                axios
                    .get(
                        encodeURI(
                            `${
                                api!.qsHostname
                            }/v1/data/tenants/${user?.tenantIdentifier}/quicksight/sleep-status`,
                        ),
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        },
                    )
                    .then(response => {
                        const value = mapTenantStatus(response.data.status);
                        setTenantStatus(value);
                    })
                    .catch(() => {
                        setTenantStatus(TenantStatus.Awake);
                        console.error('Failed to get tenant status');
                    });
            } else {
                setTenantStatus(TenantStatus.Awake);
            }

            // using axios to be able to conditionally make the /dashboards api call
            // when trying to use the usApi hook and execute the request manually, the useEffect falls into an infinate loop when execute is set as a dependency
            // hoping this approach will suffice for now
            axios
                .get(
                    encodeURI(
                        `${
                            api!.qsHostname
                        }/v1/data/dashboards/tenants/${user?.tenantIdentifier}/users/${user?.userId}/dashboards`,
                    ),
                    {
                        params: {
                            locale: getReportApiLanguage(userSelectedLang),
                        },
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                )
                .then(response => {
                    if (response.data) {
                        const availableQuickSightReports: IQuickSightReport[] =
                            response.data.results
                                .map((qsDashboard: IQuickSightDashboard) => ({
                                    ...qsDashboard,
                                    helpLink: qsDashboard.helpLink,
                                    featureToggleName:
                                        qsDashboard.featureFlag as FeatureName,
                                }))
                                .map((qsDashboard: IQuickSightDashboard) => ({
                                    ...qsDashboard,
                                    reportingArea: getReportingArea(
                                        qsDashboard.name,
                                    ),
                                }))
                                .filter(
                                    (report: IQuickSightReport) =>
                                        report.locale && report.reportingArea,
                                );

                        setQsReports(
                            availableQuickSightReports.filter(
                                dashboard =>
                                    dashboard.reportingArea ===
                                        ReportingArea.Learning ||
                                    dashboard.reportingArea ===
                                        ReportingArea.Teaching ||
                                    dashboard.reportingArea ===
                                        ReportingArea.Leading,
                            ),
                        );

                        setQuickSightDashboardsLoading(false);
                    }
                })
                .catch(reportError => {
                    if (reportError?.response) {
                        if (
                            reportError.response.data?.details?.includes(
                                'reader_qs_seats_quota_exceeded',
                            )
                        ) {
                            setUserHasQSSeat(false);
                            console.error('User does not have a QS seat');
                        }
                    }
                    setQuickSightDashboardsLoading(false);
                    setQsReports([]);
                    console.error('Failed to fetch QuickSight Dashboards');
                });
        } else if (configFeatureFlags) {
            setQuickSightDashboardsLoading(false);
            setQsReports([]);
        }
    }, [
        accessToken,
        user?.userId,
        user?.tenantIdentifier,
        userHasReportingAccess,
        userIsSignedInWithIncAuth,
        uas,
        configFeatureFlags,
        api,
        userSelectedLang,
        userHasQSSeat,
    ]);

    const closeSecondaryNavDrawerInSmDown = () => {
        if (smDown) {
            closeSecondaryNavDrawer();
        }
    };

    const sleepModeReportingPage = (area: string) => {
        const path = `/reporting/${area.toLocaleLowerCase()}`;
        return (
            <Route exact key={area.toLocaleLowerCase()} path={path}>
                <ReportAreaPage
                    reportingArea={area}
                    quickSightDashboards={learningReports}
                    wakeUpReports={wakeUpReports}
                    tenantStatus={tenantStatus}
                />
            </Route>
        );
    };

    const getRoutes = () => {
        const routes = [];
        const isInSleepMode = tenantStatus === TenantStatus.Asleep;
        const isProvisioning = tenantStatus === TenantStatus.Provisioning;

        // Use appropriate home page route

        routes.push(
            <Route exact key="home" path="/">
                <HomePage
                    userHasSettingsAccess={userHasSettingsAccess}
                    userHasDataQAndA={userHasDataQAndA}
                />
            </Route>,
        );
        routes.push(
            <Route exact key="reporting" path="/reporting">
                <ReportingPage
                    userHasReportingAccess={userHasReportingAccess}
                    tenantStatus={tenantStatus}
                    wakeUpReports={wakeUpReports}
                    userHasQSSeat={userHasQSSeat}
                />
            </Route>,
        );
        routes.push(
            <Route exact key="developer" path="/developer">
                <DeveloperPage />
            </Route>,
        );

        // Add routes based on user access
        if (userHasDataQAndA) {
            routes.push(
                <Route exact key="data-q-and-a" path="/reporting/data-q-and-a">
                    <AuthOnlyContent>
                        <DataQAndA />
                    </AuthOnlyContent>
                </Route>,
            );
        }

        routes.push(
            <Route exact key="custom-reports" path="/reporting/custom-reports">
                <AuthOnlyContent>
                    {featureFlags['bbdata.authoring.preview' as FeatureName] ? (
                        <CustomReports />
                    ) : (
                        <CustomReportsTeaser />
                    )}
                </AuthOnlyContent>
            </Route>,
        );

        if (userHasReportingAccess && userHasQSSeat) {
            if (isInSleepMode || isProvisioning) {
                routes.push(sleepModeReportingPage(ReportingArea.Learning));
            } else if (learningReports.length === 0) {
                console.error(
                    'No reports were found in the "Learning" reporting area',
                );
            } else {
                routes.push(
                    <Route key="learning.name" path="/reporting/learning/:name">
                        <AuthOnlyContent>
                            <Report
                                quickSightReports={learningReports}
                                loadingQuickSightDashboards={
                                    quickSightDashboardsLoading
                                }
                            />
                        </AuthOnlyContent>
                    </Route>,
                );
                routes.push(
                    <Route exact key="learning" path="/reporting/learning">
                        <ReportAreaPage
                            reportingArea="Learning"
                            quickSightDashboards={learningReports}
                        />
                    </Route>,
                );
            }

            if (isInSleepMode || isProvisioning) {
                routes.push(sleepModeReportingPage(ReportingArea.Teaching));
            } else if (teachingReports.length === 0) {
                console.error(
                    'No reports were found in the "Teaching" reporting area',
                );
            } else {
                routes.push(
                    <Route key="teaching.name" path="/reporting/teaching/:name">
                        <AuthOnlyContent>
                            <Report
                                quickSightReports={teachingReports}
                                loadingQuickSightDashboards={
                                    quickSightDashboardsLoading
                                }
                            />
                        </AuthOnlyContent>
                    </Route>,
                );
                routes.push(
                    <Route exact key="teaching" path="/reporting/teaching">
                        <ReportAreaPage
                            reportingArea="Teaching"
                            quickSightDashboards={teachingReports}
                        />
                    </Route>,
                );
            }

            if (isInSleepMode || isProvisioning) {
                routes.push(sleepModeReportingPage(ReportingArea.Leading));
            } else if (leadingReports.length === 0) {
                console.error(
                    'No reports were found in the "Leading" reporting area',
                );
            } else {
                routes.push(
                    <Route key="leading.name" path="/reporting/leading/:name">
                        <AuthOnlyContent>
                            <Report
                                quickSightReports={leadingReports}
                                loadingQuickSightDashboards={
                                    quickSightDashboardsLoading
                                }
                            />
                        </AuthOnlyContent>
                    </Route>,
                );
                routes.push(
                    <Route exact key="leading" path="/reporting/leading">
                        <ReportAreaPage
                            reportingArea="Leading"
                            quickSightDashboards={leadingReports}
                        />
                    </Route>,
                );
            }
        }

        if (userHasSettingsAccess) {
            routes.push(
                <Route exact key="settings" path="/settings">
                    <Settings />
                </Route>,
            );
            routes.push(
                <Route exact key="settings.user" path="/settings/user/:id">
                    <EditUserPage />
                </Route>,
            );
        }

        // Always include a route for the data dictionary page
        routes.push(
            <Route key="dictionary" path="/dictionary">
                <ErrorBoundary>
                    <DataDictionary />
                </ErrorBoundary>
            </Route>,
        );

        // Always include a route for the privacy page
        routes.push(
            <Route
                key="privacy"
                path="/privacy"
                component={PrivacyAndSecurity}
            />,
        );

        routes.push(<Redirect key="redirect.home" to="/" />);

        return routes;
    };

    const onMainMenuButtonKeyPress: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            // Need to delay a little while to get focus to work
            setTimeout(() => {
                if (closeGlobalNavDrawerButtonRef.current) {
                    closeGlobalNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const onSecondaryNavButtonKeyPress: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (closeSecondaryNavDrawerButtonRef.current) {
                    closeSecondaryNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const onCloseSecondaryNavDrawerButtonKeyPress: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (openSecondaryNavDrawerButtonRef.current) {
                    openSecondaryNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const mainMenuButton: JSX.Element = (
        <IconButton
            aria-label={t('general.mainMenu')}
            aria-expanded={globalNavOpen}
            onClick={openGlobalNavDrawer}
            onKeyDown={onMainMenuButtonKeyPress}
            data-testid="open-main-menu"
            ref={openGlobalNavDrawerButtonRef}
        >
            <ThreeBarMenu />
        </IconButton>
    );

    const secondaryNavigationButton = (
        <IconButton
            className={classes.openSecondaryNavDrawerButton}
            onClick={openSecondaryNavDrawer}
            onKeyDown={onSecondaryNavButtonKeyPress}
            aria-label={t('general.secondaryMenu')}
            aria-expanded={secondaryNavOpen}
            data-testid="open-secondary-menu"
            ref={openSecondaryNavDrawerButtonRef}
        >
            <Outline />
        </IconButton>
    );

    const getSecondaryNavTitle = () => {
        if (currentPageMatch === '/dictionary') {
            return t('dataDictionary.title');
        }
        // Report product names are not localized
        if (currentPageMatch === '/reporting/learning') {
            return t('reporting.cards.learning.label');
        }
        if (currentPageMatch === '/reporting/teaching') {
            return t('reporting.cards.teaching.label');
        }
        if (currentPageMatch === '/reporting/leading') {
            return t('reporting.cards.leading.label');
        }
        console.warn(
            'A title could not be determined for the secondary navigation.',
        );
        return '';
    };

    // Return the correct links to show in the secondary navigation
    const getSecondaryNavItems = () => {
        if (currentPageMatch === '/dictionary') {
            return [
                {
                    path: '/dictionary/entries',
                    label: t('dataDictionary.entries'),
                    testId: 'secondary-nav-data-dictionary-entries-link',
                },
                {
                    path: '/dictionary/entity-relationships',
                    label: t('dataDictionary.entityRelationships.tabText'),
                    testId: 'secondary-nav-entity-relationships-link',
                },
            ];
        }

        if (currentPageMatch === '/reporting/learning') {
            return getReportLinks(learningReports);
        }
        if (currentPageMatch === '/reporting/teaching') {
            return getReportLinks(teachingReports);
        }
        if (currentPageMatch === '/reporting/leading') {
            return getReportLinks(leadingReports);
        }
        console.warn('There are no secondary navigation links for this page.');
        return [];
    };

    const secondaryNavigation = (
        <BbThemeProvider theme="dark">
            <Drawer
                variant={mdUp ? 'persistent' : 'temporary'}
                open={secondaryNavOpen}
                onClose={closeSecondaryNavDrawer}
                data-testid="secondary-nav-drawer"
                PaperProps={{
                    className: classes.secondaryNavDrawerPaper,
                }}
            >
                <nav
                    aria-label={t('general.secondaryNavigation')}
                    className={classes.navFlexColumnWrapper}
                >
                    <div>
                        <div className={classes.secondaryNavTopSection}>
                            <Typography
                                variant="h4"
                                component="p"
                                className={classes.secondaryNavTitle}
                                style={getWordBreakStyle(userSelectedLang)}
                                data-testid="secondary-nav-title"
                            >
                                {pageHasSecondaryNav
                                    ? getSecondaryNavTitle()
                                    : ''}
                            </Typography>
                            <IconButton
                                className={
                                    classes.closeSecondaryNavDrawerButton
                                }
                                onClick={closeSecondaryNavDrawer}
                                onKeyDown={
                                    onCloseSecondaryNavDrawerButtonKeyPress
                                }
                                aria-label={t('general.closeSecondaryMenu')}
                                aria-expanded={secondaryNavOpen}
                                ref={closeSecondaryNavDrawerButtonRef}
                                data-testid="close-secondary-menu"
                            >
                                <DoubleChevronLeft />
                            </IconButton>
                        </div>
                        <div className={classes.navSection}>
                            <ListNav
                                variant="secondary"
                                listItems={
                                    pageHasSecondaryNav
                                        ? getSecondaryNavItems().map(item => {
                                              const hasBadge =
                                                  /learning-tools-adoption/.test(
                                                      item.path,
                                                  );
                                              // The default behavior is to close the secondary nav
                                              // drawer in small breakpoints and down, but this can
                                              // be overridden for a specific list item
                                              return {
                                                  onClick:
                                                      closeSecondaryNavDrawerInSmDown,
                                                  badgeType: hasBadge
                                                      ? BadgeType.NEW
                                                      : undefined,
                                                  ...item,
                                              };
                                          })
                                        : []
                                }
                            />
                        </div>
                    </div>
                </nav>
            </Drawer>
            {!globalNavOpen &&
                !secondaryNavOpen &&
                pageHasSecondaryNav &&
                secondaryNavigationButton}
        </BbThemeProvider>
    );

    // show a loading spinner while we make sure all the data for admins is ready to use
    // this allows for direct links to QuickSight reports
    const loadingDataNeededForAdmins =
        user?.hasReportAccess() &&
        userHasQSSeat &&
        quickSightDashboardsLoading &&
        path.includes('reporting');

    if (loadingDataNeededForAdmins) {
        return (
            <div className={classes.loadingIndicator}>
                <LoadingIndicator
                    aria-label={t('general.applicationLoadingAriaLabel')}
                    variant="fill-window"
                    id="loadingMainPage"
                />
            </div>
        );
    }

    return (
        <>
            <div
                className={
                    mdUp && globalNavOpen
                        ? classes.globalNavDrawerOpen
                        : classes.skipLink
                }
            >
                <SkipLink
                    targetRef={() =>
                        mainContentRef?.current?.querySelector('h1')
                    }
                >
                    {t('general.skipNavigation')}
                </SkipLink>
            </div>
            <SideBar
                isAuthenticated={isAuthenticated}
                userHasReportingAccess={userHasReportingAccess}
                userHasQSSeat={userHasQSSeat}
                userHasSettingsAccess={!!userHasSettingsAccess}
                userHasDataQAndA={userHasDataQAndA}
                logout={logoutAndNavigateToHome}
                signin={openSignInModal}
            />
            <PageExpandProvider>
                <div
                    className={classnames(classes.mainContainer, {
                        [classes.globalNavDrawerOpen]: mdUp && globalNavOpen,
                    })}
                >
                    <PageHeader
                        mainMenuButton={mainMenuButton}
                        showMainMenuButton={!globalNavOpen}
                        logout={logoutAndNavigateToHome}
                    />
                    {/* The secondary navigation needs to be rendered, even when not being used, in order 
                    for css transitions to work correctly */}
                    {secondaryNavigation}
                    <div
                        className={classnames(classes.secondaryContainer, {
                            [classes.secondaryNavDrawerOpen]:
                                mdUp && secondaryNavOpen,
                        })}
                    >
                        <main ref={mainContentRef} className={classes.content}>
                            <Suspense
                                fallback={
                                    <div className={classes.loadingIndicator}>
                                        <LoadingIndicator
                                            aria-label={t(
                                                'general.secondaryNavigationLoadingAriaLabel',
                                            )}
                                            id="loadingMainPageSecondaryNavigation"
                                        />
                                    </div>
                                }
                            >
                                <Switch>{getRoutes()}</Switch>
                            </Suspense>
                        </main>
                        <Footer />
                    </div>
                </div>
            </PageExpandProvider>
            <SignInModal />
            <SilentFeatureFlagIndicator />
        </>
    );
};
