import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import {
    Box,
    DefaultButton,
    PrimaryButton,
    IconButton,
    useSnackbar,
} from '@bb-ui/react-library';
import { Close } from '@bb-ui/icons/dist/medium';
import { useStyles } from './GlobalSettings.styles';
import { InstitutionalEmail } from './InstitutionalEmail/InstitutionalEmail';
import { SessionTimeoutDuration } from './SessionTimeout/SessionTimeoutDuration';
import { AIOptIn } from './AIOptIn/AIOptIn';
import { useTenantSettingsContext } from '../../../contexts/TenantSettingsContext';

interface GlobalSettingsProps {
    onModifiedStateChanged: (hasChanges: boolean) => void;
}

export const GlobalSettings: React.FC<GlobalSettingsProps> = ({
    onModifiedStateChanged,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { loading, defaultTimeout, data, error, patchSettings } =
        useTenantSettingsContext();

    const {
        timeout = defaultTimeout,
        email = '',
        aiOptIn = false,
    } = data || {};

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const [cancelChanges, setCancelChanges] = React.useState(false);

    // Timeout on input is in seconds but the UI displays timeout in minutes
    const [currentTimeout, setCurrentTimeout] = React.useState(
        Number(timeout) / 60,
    );
    const [isTimeoutValid, setIsTimeoutValid] = React.useState(!!timeout);
    const handleTimeoutChange = (timeout: number, isValid: boolean) => {
        setCurrentTimeout(timeout);
        setIsTimeoutValid(isValid);
    };

    const [currentEmail, setCurrentEmail] = React.useState(email ?? '');
    const [isEmailValid, setIsEmailValid] = React.useState(!!email);
    const handleEmailChange = (email: string, isValid: boolean) => {
        setCurrentEmail(email);
        setIsEmailValid(isValid);
    };

    const [currentAiOptIn, setCurrentAiOptIn] = React.useState(
        aiOptIn ?? false,
    );
    const handleAIOptInChange = (aiOptIn: boolean) => {
        setCurrentAiOptIn(aiOptIn);
    };

    const setInitialValues = React.useCallback(
        (timeout: number, email: string, aiOptIn: boolean) => {
            setCurrentTimeout(Number(timeout) / 60);
            setIsTimeoutValid(true);
            setCurrentEmail(email ?? '');
            setIsEmailValid(true);
            setCurrentAiOptIn(aiOptIn ?? false);
        },
        [],
    );

    React.useEffect(() => {
        setInitialValues(timeout, email, aiOptIn);
    }, [setInitialValues, timeout, email, aiOptIn]);

    const isDisabled = loading || !data;

    const isChanged =
        Number(timeout) !== currentTimeout * 60 ||
        aiOptIn !== currentAiOptIn ||
        email !== currentEmail;
    React.useEffect(() => {
        onModifiedStateChanged(isChanged);
    }, [onModifiedStateChanged, isChanged]);

    const handleOnCancel = () => {
        setInitialValues(timeout, email, aiOptIn);
        setCancelChanges(prevState => !prevState);
    };

    const handleOnSave = () => {
        if (patchSettings && isChanged && isTimeoutValid && isEmailValid) {
            patchSettings({
                timeout: currentTimeout * 60,
                email: currentEmail,
                aiOptIn: currentAiOptIn,
            });
        }
        setSaveRequestTriggered(true);
    };

    const [saveRequestTriggered, setSaveRequestTriggered] =
        React.useState(false);
    React.useEffect(() => {
        if (saveRequestTriggered && !loading) {
            if (error) {
                enqueueSnackbar(t('settings.globalSettings.snackbar.error'), {
                    variant: 'error',
                    action: (
                        <IconButton
                            className={classes.snackbarButton}
                            onClick={() => closeSnackbar()}
                            aria-label={t(
                                'settings.globalSettings.snackbar.close',
                            )}
                        >
                            <Close />
                        </IconButton>
                    ),
                    autoHideDuration: null,
                });
            } else {
                enqueueSnackbar(t('settings.globalSettings.snackbar.success'), {
                    variant: 'success',
                    action: (
                        <IconButton
                            className={classes.snackbarButton}
                            onClick={() => closeSnackbar()}
                            aria-label={t(
                                'settings.globalSettings.snackbar.close',
                            )}
                        >
                            <Close />
                        </IconButton>
                    ),
                });
            }
            setSaveRequestTriggered(false);
        }
    }, [
        saveRequestTriggered,
        loading,
        patchSettings,
        error,
        enqueueSnackbar,
        closeSnackbar,
        t,
        classes.snackbarButton,
    ]);

    return (
        <>
            <Typography
                variant="h2"
                className={classes.heading}
                data-testid="heading"
            >
                {t('settings.globalSettings.heading')}
            </Typography>
            <Typography
                component="p"
                className={classes.description}
                data-testid="description"
            >
                {t('settings.globalSettings.description')}
            </Typography>
            <SessionTimeoutDuration
                key="sessionTimeoutDuration"
                timeout={currentTimeout}
                defaultTimeout={defaultTimeout}
                disabled={isDisabled}
                onChange={handleTimeoutChange}
                cancelChanges={cancelChanges}
            />
            <div>
                <hr className={classes.divider} />
                <AIOptIn
                    key="aiOptIn"
                    aiOptIn={currentAiOptIn}
                    disabled={isDisabled}
                    onChange={handleAIOptInChange}
                />
            </div>
            <hr className={classes.divider} />
            <InstitutionalEmail
                key="institutionalEmail"
                email={currentEmail}
                disabled={isDisabled}
                onChange={handleEmailChange}
                cancelChanges={cancelChanges}
            />

            <Box
                display="flex"
                flexDirection="row"
                className={classes.buttonContainer}
            >
                <DefaultButton
                    className={classes.cancelButton}
                    data-testid="global-settings-cancel-button"
                    onClick={handleOnCancel}
                >
                    {t('settings.globalSettings.cancelButton')}
                </DefaultButton>
                <PrimaryButton
                    className={classes.saveButton}
                    data-testid="global-settings-save-button"
                    disabled={!isChanged || !isTimeoutValid || !isEmailValid}
                    onClick={handleOnSave}
                >
                    {t('settings.globalSettings.saveButton')}
                </PrimaryButton>
            </Box>
        </>
    );
};
