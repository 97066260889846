import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DefaultButton,
    PrimaryButton,
    DialogActions,
} from '@bb-ui/react-library';
import { useStyles } from './GlobalSettingsDialog.styles';

interface GlobalSettingsDialogProps {
    onClose: () => void;
    onStay: () => void;
}

export const GlobalSettingsDialog: React.FC<GlobalSettingsDialogProps> = ({
    onClose,
    onStay,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = React.useState(true);
    const handleOnClose = () => {
        setIsOpen(false);
        onClose();
    };
    const handleOnStay = () => {
        setIsOpen(false);
        onStay();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                maxWidth="lg"
                onClose={handleOnClose}
                aria-labelledby="global-settings-dialog-title"
            >
                <DialogTitle
                    onClose={handleOnClose}
                    id="global-settings-dialog-title"
                    data-testid="global-settings-dialog-title"
                    className={classes.title}
                >
                    {t('settings.globalSettings.dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="global-settings-dialog-description"
                        data-testid="global-settings-dialog-description"
                        className={classes.description}
                    >
                        <Trans i18nKey="settings.globalSettings.dialog.description">
                            You’ll lose your changes if you leave the page
                            without saving them.
                            <br />
                            Do you want to continue or stay on the page?
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DefaultButton onClick={handleOnClose}>
                        {t('settings.globalSettings.dialog.close')}
                    </DefaultButton>
                    <PrimaryButton onClick={handleOnStay}>
                        {t('settings.globalSettings.dialog.stay')}
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
