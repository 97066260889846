import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { OutlineButton } from '@bb-ui/react-library/dist/components/Button';
import { useStyles } from './SessionTimeoutDuration.styles';

// Timeout values in minutes
const MIN_TIMEOUT = 15;
const MAX_TIMEOUT = 480;

interface SessionTimeoutDurationProps {
    timeout: number;
    defaultTimeout: number;
    disabled?: boolean;
    onChange: (timeout: number, isValid: boolean) => void;
    cancelChanges?: boolean;
}

export const SessionTimeoutDuration: React.FC<SessionTimeoutDurationProps> = ({
    timeout,
    defaultTimeout,
    disabled,
    onChange,
    cancelChanges,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [checked, setChecked] = React.useState(false);
    const [displayError, setDisplayError] = React.useState(false);

    const toggleChecked = () => {
        setChecked(checked => !checked);
    };

    const isValidTimeout = React.useCallback(
        (timeout: string) =>
            !!(
                timeout &&
                /^\d*\.?\d+$/.test(timeout) &&
                parseFloat(timeout) >= MIN_TIMEOUT &&
                parseFloat(timeout) <= MAX_TIMEOUT
            ),
        [],
    );

    React.useEffect(() => {
        setChecked(false);
        setDisplayError(false);
    }, [cancelChanges]);

    return (
        <>
            <Typography
                variant="h3"
                className={classes.title}
                data-testid="session-timeout-title"
            >
                {t('settings.globalSettings.timeout.title')}
            </Typography>
            <FormControlLabel
                value="Switch"
                htmlFor="switch"
                control={
                    <Switch
                        id="session-timeout-switch"
                        data-testid="session-timeout-switch"
                        disabled={disabled}
                        checked={checked}
                        onChange={toggleChecked}
                    />
                }
                label={t('settings.globalSettings.timeout.switch')}
            />

            <br />

            <div className={classes.timeoutContainer}>
                <TextField
                    disabled={!checked}
                    id="session-timeout-value"
                    data-testid="session-timeout-value"
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: MIN_TIMEOUT,
                            max: MAX_TIMEOUT,
                        },
                    }}
                    className={classes.input}
                    label={t('settings.globalSettings.timeout.input')}
                    value={timeout !== 0 ? timeout.toString() : ''}
                    onChange={event => {
                        const enteredTimeout = event.target.value.trim();
                        setDisplayError(false);
                        onChange(
                            Number(enteredTimeout),
                            isValidTimeout(enteredTimeout),
                        );
                    }}
                    onBlur={event => {
                        const enteredTimeout = event.target.value.trim();
                        setDisplayError(!isValidTimeout(enteredTimeout));
                    }}
                    error={displayError}
                    helperText={t(
                        'settings.globalSettings.timeout.description',
                    )}
                />
                <Typography component="div" data-testid="session-timeout-unit">
                    {t('settings.globalSettings.timeout.unit')}
                </Typography>
            </div>

            <OutlineButton
                disabled={!checked}
                data-testid="session-timeout-reset-button"
                onClick={() => {
                    setDisplayError(false);
                    onChange(defaultTimeout / 60, true);
                }}
            >
                {t('settings.globalSettings.timeout.button')}
            </OutlineButton>
        </>
    );
};
