import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            title: {
                weight: theme.typography.fontWeightBold,
            },
            description: {
                fontWeight: theme.typography.fontWeightRegular,
                lineHeight: '2em',
            },
        }),
    {
        name: 'InstitutionalEmail-',
    },
);
