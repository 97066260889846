import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tabs } from '@bb-ui/react-library/dist/components/Tabs';
import { Tab } from '@bb-ui/react-library/dist/components/Tab';
import { TabPanel } from '@bb-ui/react-library/dist/components/TabPanel';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { Prompt, useHistory } from 'react-router-dom';
import { useStyles } from './Settings.styles';
import { Frame } from '../Frame/Frame';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { MainHeader } from '../MainHeader/MainHeader';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { SnowflakeAccountSettings } from './SnowflakeAccountSettings/SnowflakeAccountSettings';
import { LicenseManagement } from './LicenseManagement/LicenseManagement';
import { GlobalSettings } from './GlobalSettings/GlobalSettings';
import { IQSSeat, IQuotas } from './QSSeats.types';
import { useApi } from '../../hooks/useApi';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { getTenantId } from '../../utilities/utilities';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useUser } from '../../hooks/useUser';
import { useFeatureContext } from '../../contexts/FeatureContext';
import { GlobalSettingsDialog } from './GlobalSettings/GlobalSettingsDialog/GlobalSettingsDialog';

export function Settings() {
    const classes = useStyles();
    const { t } = useTranslation();
    const bbUser = useUser();

    const { api, uas } = useAppConfigContext();
    const { user } = useAuth0Context();
    const tenantId = getTenantId(user, uas);
    const { globalSettingsEnabled } = useFeatureContext();
    const history = useHistory();

    useApplicationTitle(t('settings.title'));

    const getHelpLink = useHelpLinks();

    const [tabIndex, setTabIndex] = React.useState(0);
    const [nextTabIndex, setNextTabIndex] = React.useState(0);
    const [nextPath, setNextPath] = useState('');
    const [modifiedStateChanged, setModifiedStateChanged] = useState(false);
    const [modifiedStateChangedTabChange, setModifiedStateChangedTabChange] =
        useState(false);
    const [
        modifiedStateChangedLeavingPage,
        setModifiedStateChangedLeavingPage,
    ] = useState(false);

    const handleTabChange = (
        event: ChangeEvent<unknown>,
        index: React.SetStateAction<number>,
    ) => {
        if (modifiedStateChanged && !modifiedStateChangedTabChange) {
            // To stay on the same tab before user decides to cancel or save changes
            setNextTabIndex(index);
            setModifiedStateChangedTabChange(true);
        } else {
            setTabIndex(index);
        }
    };

    const [tableData, setTableData] = useState<IQSSeat[]>([]);
    const [quotaForReaders, setQuotaForReaders] = useState<number>(0);
    const [quotaForAuthors, setQuotaForAuthors] = useState<number>(0);

    const url = encodeURI(
        `${api!.qsHostname}/v1/data/dashboards/tenants/${tenantId}/users`,
    );

    const {
        data: licenseManagementData,
        error: licenseManagementError,
        loading: licenseManagementLoading,
    } = useApi({
        url,
        useAuth: true,
        method: 'GET',
        executeType: bbUser?.isUniversalUser() ? 'automatic' : 'manual',
    });

    useEffect(() => {
        if (licenseManagementData) {
            setTableData(
                (licenseManagementData as { results: IQSSeat[] }).results,
            );
            setQuotaForReaders(
                (licenseManagementData as { quotas: IQuotas }).quotas.Reader,
            );
            setQuotaForAuthors(
                (licenseManagementData as { quotas: IQuotas }).quotas.Author,
            );
        }
    }, [licenseManagementData]);

    if (licenseManagementError) {
        console.error(licenseManagementError);
    }

    const showContentForSettingsPage = bbUser?.isUniversalUser() ? (
        <Frame className={classes.paper}>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-orientation="horizontal"
                className={classes.tabs}
            >
                <Tab
                    id="tab-0"
                    label={t('settings.licenseManagement.title')}
                    aria-controls="tab-0"
                    data-testid="licenseManagementTab"
                />
                <Tab
                    id="tab-1"
                    label={
                        /* prettier-ignore */
                        <p>
                                <Trans i18nKey="settings.userManagement.title">
                                    <span lang="en">Snowflake</span> Account Settings
                                </Trans>
                            </p>
                    }
                    aria-controls="tab-1"
                    data-testid="snowflakeAccountSettingsTab"
                />
                {globalSettingsEnabled && (
                    <Tab
                        id="tab-2"
                        label={t('settings.globalSettings.title')}
                        aria-controls="tab-2"
                        data-testid="globalSettingsTab"
                    />
                )}
            </Tabs>
            <TabPanel panelId="tabpanel-1" ariaLabelledby={`tab-${tabIndex}`}>
                {tabIndex === 0 && (
                    <LicenseManagement
                        key="licenseManagement"
                        tableData={tableData}
                        quotaForReaders={quotaForReaders}
                        quotaForAuthors={quotaForAuthors}
                        loading={licenseManagementLoading}
                        error={!!licenseManagementError}
                    />
                )}
                {tabIndex === 1 && (
                    <SnowflakeAccountSettings key="snowflakeSettings" />
                )}
                {globalSettingsEnabled && tabIndex === 2 && (
                    <GlobalSettings
                        key="globalSettings"
                        onModifiedStateChanged={setModifiedStateChanged}
                    />
                )}
            </TabPanel>
        </Frame>
    ) : (
        <Frame className={classes.paper}>
            <Typography
                variant="h2"
                component="h2"
                className={classes.title}
                data-testid="snowflakeAccountSettings"
            >
                {/* prettier-ignore */}
                <Trans i18nKey="settings.userManagement.title">
                        <span lang="en">Snowflake</span> Account Settings
                    </Trans>
            </Typography>
            <SnowflakeAccountSettings key="snowflakeSettings" />
        </Frame>
    );

    return (
        <>
            <MainHeader title={t('settings.title')} />
            <ResponsiveBox>
                {showContentForSettingsPage}
                <ActionAreaButtons helpLink={getHelpLink('settings')} />
            </ResponsiveBox>

            <Prompt
                when={modifiedStateChanged && !modifiedStateChangedLeavingPage}
                message={location => {
                    setModifiedStateChangedLeavingPage(true);
                    setNextPath(location.pathname);
                    return false;
                }}
            />
            {/* Navigation to another page */}
            {modifiedStateChangedLeavingPage && tabIndex === 2 && (
                <GlobalSettingsDialog
                    onClose={() => {
                        setModifiedStateChanged(false);
                        setModifiedStateChangedLeavingPage(false);
                        history.push(nextPath);
                    }}
                    onStay={() => {
                        setModifiedStateChangedLeavingPage(false);
                    }}
                />
            )}
            {/* Navigation to another tab within the page */}
            {modifiedStateChangedTabChange && tabIndex === 2 && (
                <GlobalSettingsDialog
                    onClose={() => {
                        setModifiedStateChanged(false);
                        setModifiedStateChangedTabChange(false);
                        setTabIndex(nextTabIndex);
                    }}
                    onStay={() => {
                        setModifiedStateChangedTabChange(false);
                    }}
                />
            )}
        </>
    );
}
