import React from 'react';
import { useAppConfigContext } from '../AppConfigProvider';
import { IApiConfig, useApi } from '../../hooks/useApi';
import { UniversalAuthUser, useUser } from '../../hooks/useUser';
import {
    TenantSettings,
    TenantSettingsContextProps,
    TenantSettingsResponse,
} from './TenantSettingsContext.types';

const DEFAULT_TIMEOUT = 900; // Timeout value in seconds

export const TenantSettingsContext =
    React.createContext<TenantSettingsContextProps>({
        loading: false,
        defaultTimeout: DEFAULT_TIMEOUT,
    });

export const useTenantSettingsContext = () =>
    React.useContext(TenantSettingsContext);

export const TenantSettingsProvider: React.FunctionComponent = props => {
    const { api, settings } = useAppConfigContext();
    const { children } = props;
    const user = useUser();
    const tenantId = user?.isUniversalUser()
        ? (user as UniversalAuthUser).tenantId
        : null;

    const settingsApiConfig = React.useMemo<IApiConfig>(
        () => ({
            url: encodeURI(
                `${api?.accountSettingsHostname}/v1/tenants/${tenantId}/clientSettings`,
            ),
            useAuth: true,
            executeType: 'manual',
        }),
        [api, tenantId],
    );
    const getSettingsApiConfig = React.useMemo<IApiConfig>(
        () => ({ ...settingsApiConfig, method: 'GET' }),
        [settingsApiConfig],
    );
    const patchSettingsApiConfig = React.useMemo<IApiConfig>(
        () => ({ ...settingsApiConfig, method: 'PATCH' }),
        [settingsApiConfig],
    );

    const {
        data,
        loading: fetching,
        error: fetchError,
        execute: fetch,
    } = useApi<TenantSettingsResponse>(getSettingsApiConfig);

    const defaultTimeout = settings?.idleSessionTimeout
        ? settings.idleSessionTimeout / 1000
        : DEFAULT_TIMEOUT;

    if (data && !data.settings.timeout) {
        data.settings.timeout = defaultTimeout;
    }

    const {
        data: patchData,
        loading: patching,
        error: patchError,
        execute: patch,
    } = useApi<TenantSettingsResponse>(patchSettingsApiConfig);

    React.useEffect(() => {
        if (tenantId) {
            fetch();
        }
    }, [fetch, tenantId]);

    const doPatch = React.useCallback(
        (settings: Partial<TenantSettings>) => {
            patch({ data: { settings } });
        },
        [patch],
    );

    return (
        <TenantSettingsContext.Provider
            value={{
                loading: fetching || patching,
                data: patchData?.settings ?? data?.settings,
                error: fetchError || patchError,
                patchSettings: tenantId ? doPatch : undefined,
                defaultTimeout,
            }}
        >
            {children}
        </TenantSettingsContext.Provider>
    );
};

export default TenantSettingsProvider;
